import Image from "next/image";
import { FC, ReactNode } from "react";
interface USPProps {
  icon: string;
  title: string;
  children: ReactNode;
}
export const USP: FC<USPProps> = ({ icon, title, children }) => {
  return (
    <div className="flex flex-col items-center text-center max-w-[280px] pb-8 lg:pb-0">
      <Image src={icon} alt="price" width={115} height={115} />
      <div className="font-bold text-[30px] leading-[34.08px] pt-4 pb-2">
        {title}
      </div>
      <div className="text-[22px] leading-[26.4px]">{children}</div>
    </div>
  );
};
