import Image from "next/image";
import { FC } from "react";
interface CategoryProps {
  title: string;
  image: string;
}
export const Category: FC<CategoryProps> = ({ title, image }) => {
  return (
    <div className="relative w-[360px] h-[400px]">
      <div className="relative z-20 text-white text-[24px] lg:text-[30px] p-6 font-bold w-[280px]">
        {title}
      </div>
      <Image
        src={image}
        layout="fill"
        alt={title}
        className="rounded-xl z-10"
      />
    </div>
  );
};
