import { Category } from "./category";

export const SectionCategory = () => {
  return (
    <div className="text-black flex flex-col items-center py-8 lg:py-24 bg-jooalan-gray-50">
      <div className="text-[24px] lg:text-[42px] font-bold pb-8 lg:pb-16 text-center">
        500+ produk tersedia di Jooalan
      </div>
      <div className="flex gap-x-4 px-4 lg:px-12 no-scrollbar w-full overflow-x-scroll justify-center">
        <Category
          title="Perlengkapan Ibadah"
          image="/landing/img/categories/ibadah.png"
        />
        <Category
          title="Produk Rumah Tangga"
          image="/landing/img/categories/home-and-living.png"
        />
        <Category
          title="Produk Ibu dan Anak"
          image="/landing/img/categories/mom-and-baby.png"
        />
        <Category
          title="Produk Kesehatan"
          image="/landing/img/categories/medical.png"
        />
      </div>
    </div>
  );
};
