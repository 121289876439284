import { USP } from "./usp";
export const SectionUSP = () => {
  return (
    <div className="text-black flex flex-col items-center py-16 lg:pt-24 lg:pb-36">
      <div className="text-[24px] lg:text-[42px] font-bold pb-8 lg:pb-16 text-center">
        Cuan Bersama Jooalan
      </div>
      <div className="flex flex-col lg:flex-row gap-x-24">
        <USP icon="/landing/img/rp.gif" title="Harga Masuk">
          Harga terbaik bisa kamu temukan di sini!
        </USP>
        <USP icon="/landing/img/jooalan.gif" title="Barang Tersedia">
          Stok produk selalu tersedia di gudang kami.
        </USP>
        <USP icon="/landing/img/star.gif" title="Kualitas Premium">
          Produk dikurasi dengan kualitas terbaik.
        </USP>
      </div>
    </div>
  );
};
