"use client";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useOsPlatform } from "../../../hooks/use-os-platform";

const taglines = ["Jualan", "Online Seller", "Stok Barang"];

export const SectionHero = () => {
  const { isIos, isAndroid, isDefault } = useOsPlatform();

  const [taglineIndex, setTaglineIndex] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (taglineIndex + 1 >= taglines.length) {
        setTaglineIndex(0);
      } else {
        setTaglineIndex(taglineIndex + 1);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [taglineIndex]);

  return (
    <div className="relative h-[580px] lg:h-[700px] w-full">
      <div
        key={taglineIndex}
        className="absolute bg-jooalan-primary-100 rounded-xl w-full lg:w-[720px] h-[320px] lg:h-[480px] z-20 -top-8 lg:top-[96px] left-0 lg:left-[48px] py-14 lg:py-16 px-8 lg:px-20 text-center lg:text-left"
      >
        <div className="text-[28px] lg:text-[52px] font-bold leading-[38.4px] lg:leading-[59.8px] transition duration-300 ease-in-out lg:-translate-x-6 text-white h-[120px] lg:h-[180px]">
          {`Tempat belanja Kebutuhan `}
          <span className="text-[28px] lg:text-[52px] font-bold leading-[38.4px] lg:leading-[59.8px] text-jooalan-secondary">
            {taglines[taglineIndex]}
          </span>
        </div>
        <div className="text-[20px] lg:text-[32px] text-white font-weight-[500] py-[12px] lg:py-8 transition duration-700 ease-in-out lg:-translate-x-6">
          Mulai cuanmu sekarang!
        </div>
        <div className="flex gap-x-2 lg:-translate-x-6 justify-center items-center">
          {(isAndroid || isDefault) && (
            <Link href={process.env.NEXT_PUBLIC_PLAY_STORE ?? "/"}>
              <div className="flex flex-col items-end justify-start lg:-mt-8 cursor-pointer">
                <Image
                  className="flex lg:hidden"
                  src="/landing/img/play-store.png"
                  width={134}
                  height={54}
                  alt="Play Store"
                  layout="fixed"
                />
                <Image
                  className="hidden lg:flex"
                  src="/landing/img/play-store.png"
                  width={184}
                  height={66}
                  alt="Play Store"
                  layout="fixed"
                />
              </div>
            </Link>
          )}
          {(isIos || isDefault) && (
            <Link href={process.env.NEXT_PUBLIC_APP_STORE ?? "/"}>
              <div className="flex flex-col items-start lg:justify-start lg:-mt-8 cursor-pointer">
                <Image
                  className="flex lg:hidden"
                  src="/landing/img/app-store.png"
                  width={130}
                  height={47}
                  alt="App Store"
                  layout="fixed"
                />
                <Image
                  className="hidden lg:flex"
                  src="/landing/img/app-store.png"
                  width={184}
                  height={56}
                  alt="App Store"
                  layout="fixed"
                />
              </div>
            </Link>
          )}
        </div>
      </div>
      <Image
        src="/landing/img/jln-hero.png"
        alt="logo"
        className="hidden lg:block object-cover z-10"
        layout="fill"
      />
      <Image
        src="/landing/img/jln-hero-mobile.png"
        alt="logo"
        className="block lg:hidden object-contain z-10 mt-[128px]"
        layout="fill"
      />
    </div>
  );
};
