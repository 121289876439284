/* eslint-disable @next/next/no-img-element */
import React from "react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { SectionHero } from "../modules/landing/components/section-hero";
import { SectionUSP } from "../modules/landing/components/section-usp";
import { SectionCategory } from "../modules/landing/components/section-category";
import { Header } from "../modules/landing/components/header";
import { Footer } from "../modules/landing/components/footer";
import { SEO } from "../components/seo/seo";

SwiperCore.use([Pagination, Navigation]);

export default function Index() {
  return (
    <main className="flex flex-col justify-between bg-white">
      <SEO title="Jooalan" path="/" description="Mulai cuanmu sekarang" />
      <Header />
      <SectionHero />
      <SectionUSP />
      <SectionCategory />
      <Footer />
    </main>
  );
}
